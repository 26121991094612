@import "../../Core/variables";

$padding-size: 25px;

.scrollbar {
  width: 100% !important;
  height: 620px !important;
}

.modal {
  max-width: 342px !important;
  border-radius: 10px !important;

  & > div {
    min-width: 342px;
  }
}

.editEntry {
  padding: 0 24px;
  margin-bottom: 20px;

  & > * {
    margin-top: 10px;
  }

  &__line {
    width: 100%;
    height: 1px;
    margin: 20px 0;
    background: #d0dfef;
  }

  &__formControl {
    margin-top: 20px;
    margin-bottom: 20px;

    &__labelBlock {
      display: flex;

      label {
        margin-right: 20px;
      }
    }
  }

  &__infoBlock {
    display: flex;
    align-items: center;
    padding: 0 11px;
    height: 42px;
    background-color: #f5f9fc;
    border-radius: 13px;

    & > span {
      &:nth-child(2) {
        margin-right: auto;
      }

      &:nth-child(4) {
        margin-right: 19px;
      }
    }

    &__label {
      margin-right: 5px;
      color: #cbd1d8;
      font-size: 13px;
      line-height: 16px;
      font-family: "Helvetica Neue Bold", sans-serif;
    }

    &__value {
      font-size: 18px;
      line-height: 39px;
      font-family: "Helvetica Neue Bold", sans-serif;
      color: #333945;
    }
  }
}

.buttonSave {
  height: 36px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  outline: transparent;
  letter-spacing: -0.17px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: $blue-grownu;
  border: 1px solid transparent;
}

.miniCardInfo {
  display: flex;
  align-items: center;
  padding: 0 11px;
  height: 42px;
  background-color: #f5f9fc;
  border-radius: 13px;
  $miniCardInfo: &;

  &__label {
    color: #0085ff;
    font-size: 13px;
    font-family: "Helvetica Neue Bold", sans-serif;
    margin-left: 8px;
    margin-right: auto;
  }

  &__value {
    font-family: "Helvetica Neue Bold", sans-serif;
    font-size: 23px;
    color: #333945;
    margin-left: 5px;
  }

  &__time {
    font-family: "Helvetica Neue Bold", sans-serif;
    font-size: 16px;
    color: #5d5e62;
  }

  &_orange {
    background-color: #fff7ea;

    #{$miniCardInfo}__label {
      color: #ff9d00;
    }
  }
}

.pendingIcon {
  width: 19px !important;

  g {
    fill: white;
  }

  path {
    fill: #0085ff;
  }
}

.pauseIcon {
  width: 18px !important;

  path {
    fill: #ff9d00;
  }
}

.timePart {
  background-color: #f5f9fc;
  height: 110px;
  border-radius: 13px;
  padding: 4px 13px 6px 13px;
  $timePart: &;

  &__label {
    font-size: 14px;
    font-family: 'Helvetica Neue Bold', sans-serif;
    color: #808f94;
    margin-bottom: 1px;
  }

  &__container {
    display: flex;

    input {
      background-color: #fcfdfd;
      border: 1px solid #e2f1fd;
      color: black;
      font-size: 15px;
      border-radius: 4px;
      padding: 0 10px;
      font-family: Helvetica Neue, sans-serif;
      width: 100%;

      &:focus {
        outline: none;
      }

      &:first-child {
        margin-right: 15px;
      }
    }

    &__select {
      margin-right: 15px;
    }

    &__button {
      cursor: pointer;
      outline: transparent;
      border: 1px solid transparent;
      letter-spacing: -0.17px;
      background-color: transparent;
      width: 20px;
      margin-left: 10px;

      svg {
        width: 14px;
      }

      &__refreshIcon path {
        fill: #313946;
      }
    }
  }

  &__inputs {
    width: calc(100% - 30px);
    margin-top: 10px;

    > div {
      width: 100%;
      height: 30px;
      margin-bottom: 10px;

      input {
        height: 30px;
      }
    }
  }

  &_orange {
    background-color: #fff7e9;
  }

  &_remove {
    background-color: #ffc0c0;

    #{$timePart}__label {
      opacity: 0.39;
    }
  }
}

.scrollbarTrackY {
  background-color: transparent !important;
  border-radius: 10px !important;
  width: 7px !important;
  height: calc(100% - 50px) !important;
  top: 30px !important;
  right: 0 !important;
  z-index: 2;

  div {
    background-color: #d5dde1 !important;
    border-radius: 10px !important;
  }
}
