@import "../variables";

.linkBlock {
  display: flex;
}

.rightLinkBlock {
  display: flex;
  height: 71px;
}

.logoWrapper {
  border-right: 1px solid #d0dfef;
  height: 71px;

  & > svg {
    margin: 25px 28px 18px 5px;
    width: 95px;
    height: 28px;
  }
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 70px;
  min-width: 70px;
  padding: 14px 6px 10px 6px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  color: $gray;
  background-color: transparent;
  border: none;
  box-sizing: content-box;
  font: 14px Helvetica Neue, sans-serif;
  cursor: pointer;
  margin: 0;

  .icon path {
    fill: $gray !important;
  }

  &__text {
    display: inline-block;
    padding-top: 5px;
    line-height: 16px;
    white-space: nowrap;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background: $blue;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  &_active,
  &:hover {
    background: $blue-100;
    color: $blue-2;

    .icon path {
      fill: $blue-2 !important;
    }

    g[data-name="schedule"] {
      path[data-name="Контур 13723"],
      g[data-name="Эллипс 7"] {
        stroke: $blue-2 !important;
      }
    }

    svg[data-name="settings-icon"],
    svg[data-name="events-icon"],
    svg[data-name="help-icon"] {
      path {
        fill: $blue-2 !important;
      }
    }

    svg[data-name="logbook-icon"] {
      path {
        fill: $blue-2 !important;
        stroke: $blue-2 !important;
      }
    }

    g[data-name="Component 4 – 1"] {
      path[data-name="Subtraction 15"] {
        stroke: $blue-2 !important;
        fill: $blue-2 !important;
      }

      g[data-name="Ellipse 8"],
      g[data-name="Path 13770"] {
        stroke: $blue-2 !important;
      }
    }

    &::before {
      opacity: 1;
    }
  }
}

.userBlock {
  width: 100%;
  max-width: 210px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding-left: 25px;
  cursor: pointer;

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &__name {
    color: $gray;
    padding-left: 10px;
    font-weight: 600;
    font-size: 16px;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background: $light-blue;
  }
}
