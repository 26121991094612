.inputNumber {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 48px;
  height: 24px;
  border: 1px solid #d9dfe3;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  $inputNumber: &;

  &_disabled {
    background-color: #fafbfc;
    cursor: pointer;
    pointer-events: none;

    #{$inputNumber} {
      &__input {
        color: #c6cace;
      }

      &__increment path,
      &__decrement path {
        stroke: #c6cace;
      }
    }
  }

  &__input {
    height: 24px;
    width: 35px;
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    color: #333945;
    padding: 0 0 0 7px;
    margin-top: 1px;

    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button {
      display: none;
    }

    &:focus {
      outline: none;
    }
  }

  &__increment,
  &__decrement {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 10px;
    height: 10px;
    right: 4px;
    border: none;
    background-color: transparent;
    //background-color: rgba(red, 0.2);
    cursor: pointer;
    z-index: 3;
  }

  &__increment {
    top: 1px;
  }

  &__decrement {
    bottom: 1px;
    transform: scale(1, -1);
  }
}
