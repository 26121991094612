.checkboxGroup {
  display: flex;
  flex-direction: column;
  border: 1px solid #d0dfef;
  border-radius: 4px;
  margin-bottom: 10px;
  max-height: 100%;
  //width: 260px;
  margin-top: 10px;
}

.contentBox {
  display: flex;
  flex-direction: column;
  padding: 6px;
  max-height: 315px;
  height: 230px;
  //overflow-y: scroll;
}

.button {
  font-family: 'Helvetica Neue Medium', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #0087ff;
  letter-spacing: -0.17px;
  height: 30px;
  background: #fff;
  cursor: pointer;
  outline: transparent;
  border: none;
  border-radius: 4px 4px 0 0;
  width: 50%;

  &:first-child {
    border-right: 1px solid #d0dfef;
    border-radius: 4px 0 0 0;
  }

  &:disabled {
    color: #c3d3e3;
    pointer-events: none;
  }
}

.headerRow {
  border-bottom: 1px solid #d0dfef;
}
