.checkboxGroup {
  display: flex;
  flex-direction: column;
  //border-top: 1px solid rgba(112, 112, 112, 0.24);
  margin-left: 6%;
}

.contentBox {
  display: flex;
  flex-direction: column;
  padding: 6px 6px 6px 0;
}
