@import "../variables";

.toolsOuter {
  position: relative;
  z-index: 1;
}

.contentBox {
  width: 350px;
  background-color: #fff;
  border: 1px solid $blue-grownu;
  border-radius: 4px;
  padding: 10px;
  position: absolute;
  right: 0;
  margin-top: 10px;
  z-index: 3;
  color: #333945;

  > svg {
    position: absolute;
    top: 10px;
    width: 20px;
    height: 20px;
    right: 10px;
    color: #808f94;
    cursor: pointer;
  }
}

.openChangeLog {
  width: 100%;
  background-color: #fff;
  border: 1px solid $blue-grownu;
  color: #333945;
  margin-top: 10px;
}

.inputWrapper {
  cursor: pointer;
  position: relative;
  width: 100%;
  min-width: 60px;
  max-width: 176px;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    width: 12.077px;
    height: 7.039px;
    top: 15px;
    right: 10px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.077' height='7.039' viewBox='0 0 12.077 7.039'%3E%3Cpath id='Path_1161' data-name='Path 1161' d='M2360.908,1120.023l4.625,4.624,4.624-4.624' transform='translate(-2359.494 -1118.609)' fill='none' stroke='%230085ff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E%0A") right center no-repeat;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transform: rotate(-90deg);
  }
}

.inputWrapperOpened::before {
  transform: rotate(0deg);
}

.customSelect {
  width: 100%;
  height: 36px;
  border: 1px solid $blue-grownu;
  border-radius: 4px;
  outline: none;
  padding: 5px 32px 5px 10px;
  font-family: Helvetica Neue, sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #333945;
  box-sizing: border-box;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  
  svg {
    width: 20px;
    height: 20px;
    color: #808f94;
    margin-right: 5px;
  }
}

.checkboxesBox {
  color: #333945;
  border: 1px solid #d0dfef;
  border-radius: 3px;
  margin-top: 10px;
  padding: 10px;
}

.generalBlock {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  margin-left: -8px; 
  justify-content: space-between;

  label {
    max-width: 85%;
  }
}

.tooltipBlock {
  margin-left: 20px;
}