.defaultShiftTime {
  height: 32px;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-family: 'Helvetica Neue Medium', sans-serif;
  font-size: 12px;
  border-radius: 4px;
  color: #000;
  background-color: #dfe7ef;
}
