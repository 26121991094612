@import "../../variables";

.date {
  display: flex;
  font-family: "Helvetica Neue Bold", sans-serif;
  font-size: 14px;
  color: $gray;
  text-align: center;
}

.result {
  display: flex;
  font-family: "Helvetica Neue Bold", sans-serif;
  font-size: 14px;
  text-align: center;
}

.success {
  color: #5CA704;
}

.error {
  color: #fd0d1b;
}

.overlay {
  background: #fff;
  border-radius: 12px;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  padding: 0;
  transition: opacity 0.2s;
  display: none;
  justify-content: center;
  align-items: center;
}

.overlayActive {
  opacity: 0.9;
  transition: opacity 0.2s;
  display: flex;
}