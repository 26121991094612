.dayTotal {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  position: relative;
  $item: &;

  &:hover {
    background: #EBF7FA;
  }

  &_empty #{$item}__content {
    cursor: default;
  }

  &_night {
    color: #fd9d27;
    display: block;
    border-top: 1px solid #fd9d27;
    text-align: right;
  }

  &__empty {
    color: #7E8E95;
    display: flex;
    align-items: center;
    font-size: 10px;
    justify-content: flex-start;

    &__circle {
      background: #EFF4F7;
      position: relative;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: inline-block;
      margin-right: 5px;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 6px;
        bottom: 6px;
        left: 6px;
        right: 6px;
        border-radius: 50%;
        border: dashed 1px #B8BEC4;
      }
    }
  }

  &__content {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: transparent;
    border: none;

    &__users {
      display: flex;

      &__avatar {
        object-fit: cover;
      }

      &__count {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #302d4a;
        color: #f9fbfc;
        font-family: 'Helvetica Neue Medium', sans-serif;
        font-size: 10px;
      }

      &__avatar,
      &__count {
        width: 24px;
        height: 24px;
        border-radius: 12px;

        &:not(:first-child) {
          margin-left: -12px;
        }
      }
    }

    &__statistic {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      font-size: 10px;
      line-height: 12px;
      color: #818f94;

      &__hours {
        font-family: Helvetica Neue, sans-serif;
      }

      &__money {
        font-family: "Helvetica Neue Medium", sans-serif;
      }
    }

    &__emptyIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f0f4f7;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      position: relative;

      &::after {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 6px;
        box-sizing: border-box;
        border: 1px dashed #b9bec3;
      }
    }

    &__noWorkers {
      font-family: Helvetica Neue, sans-serif;
      font-size: 10px;
      line-height: 12px;
      color: #818f94;
      cursor: default;
    }
  }

  &:last-child #{$item}__modal {
    right: 56px;
  }

  &__modal {
    position: fixed;
    bottom: 75px;
    width: 279px;
    background-color: #fff;
    box-shadow: 0 3px 20px rgba(#647d93, 0.27);
    border-radius: 6px;
    z-index: 999;
    padding: 15px 5px 0 5px;

    &__title {
      font-family: "Helvetica Neue Medium", sans-serif;
      font-size: 14px;
      line-height: 16px;
      height: 16px;
      margin-bottom: 9px;
      margin-left: 10px;
    }

    &__close {
      position: absolute;
      right: 15px;
      top: 13px;
      cursor: pointer;
      width: 20px !important;
      height: 20px !important;
      color: #8a949f;
    }

    .scrollableContent {
      height: 300px !important;
      max-height: 444px !important;
      width: 100% !important;

      .scrollbarTrackY {
        display: flex;
        justify-content: flex-end;
        width: 5px !important;
        height: calc(100% - 10px) !important;
        top: 5px !important;
        bottom: 5px !important;
        right: 0 !important;
        background-color: transparent !important;
        border-radius: 10px !important;
        z-index: 1 !important;
        margin-left: 5px;

        div {
          background-color: #d5dde1 !important;
          border-radius: 10px !important;
          width: 5px !important;
        }
      }
    }
  }
}
