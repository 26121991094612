@import "../../Core/variables";

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $gray;
  font-family: $font-family;
}

.card {
  display: flex;
  min-height: 400px;
  max-width: 536px;
  width: 100%;
  //min-width: 536px;
  flex-direction: column;
  padding: 50px 115px;
  align-items: center;
}

.description {
  margin-top: -14px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 16px;
  color: #808f94;

  p {
    font-size: 14px;
  }
}

.black {
  color: black;
}

.buttonBlock {
  margin-top: 11px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-self: center;

  button {
    font-family: Helvetica Neue, sans-serif;
  }
}

.forgotLink {
  color: #fff;
  background: #0085ff;
  border: 1px solid transparent;
  cursor: pointer;
  outline: transparent;
  white-space: nowrap;
  position: relative;
  margin: 0;
  box-shadow: 0 1px 2px rgba(9, 16, 43, 0.03);
  min-height: 42px;
  line-height: 16px;
  border-radius: 5px;
  font-size: 14px;
  letter-spacing: -0.02em;
  padding: 12px 24px;
  font-family: Helvetica Neue, sans-serif;
  height: 42px;
  min-width: 150px;
  box-sizing: border-box;
  text-decoration: none;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.errorBlock {
  margin-top: 3px;
  height: 20px;
  color: $red;
  text-align: right;

  p {
    margin: 0;
    font-size: 12px;
  }
}

.sentTitle {
  color: $gray;
}

.error {
  color: $red;
  margin-top: 15px;
  font-size: $small-font-size;
}

.formItem {
  margin-top: 10px;
}
