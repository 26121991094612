.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
}

.table {
  position: relative;
}

.noData {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;
  color: #808f94;
  border: 1px solid rgba(226, 235, 244, 0.85);
  border-radius: 12px;
  background-color: white;
}

.labelBLock {
  display: grid;
  align-items: center;
  grid-template-columns: auto 20px 1fr;
  grid-column-gap: 25px;
  line-height: 1;
  padding-bottom: 9px;

  & label {
    font-family: "Helvetica Neue Bold", sans-serif;
    padding-bottom: 0;
  }

  .right {
    text-align: right;

    & button {
      font-family: "Helvetica Neue Bold", sans-serif;
    }
  }
}
