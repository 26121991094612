@import "../Core/variables";

$actions-min-height: 114px;
$sidebar-border-radius: 12px;

.container {
  background-color: #edf4fa;
  box-sizing: border-box;
  display: flex;
  //justify-content: center;
  //height: 100vh;
  height: 100%;
  max-height: calc(100vh - 120px);
  min-height: 435px;
  //padding: 0 25px 0 0;
  //width: calc(100vw - 20px * 2);
  //min-width: fit-content;
  padding: 0 6px;
}

.settingsCustom {
  margin-left: 6%;
  margin-bottom: -6px;
}

.avatar {
  width: 85px;
  height: 85px;
  object-fit: cover;
  border-radius: 50%;
}

.appHeader {
  background-color: #fff;
  border: 1px solid rgba(226, 235, 244, 0.85);
  border-radius: 12px;
  box-sizing: border-box;
  box-shadow: 0 3px 16px rgba(226, 233, 237, 0.61);
  display: flex;
  padding: 13px 20px;
  width: 100%;
}

.delimiter {
  margin-left: 15px;
  height: 15px;
}

.leftContent {
  //padding: 15px 15px 25px 25px;
  overflow: hidden;
  width: calc(100% - 287px);
}

.rightSidebar {
  position: relative;
  background-color: #fff;
  border-radius: $sidebar-border-radius;
  box-shadow: 0 3px 16px rgba(226, 233, 237, 0.61);
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 20px 10px;
  text-align: center;
  min-width: 287px;
  max-width: 287px;
  margin-left: 16px;
  min-height: 400px;

  .hero {
    position: relative;
    height: 100%;

    & > button {
      position: absolute;
      top: -13px;
      left: 8px;
    }
  }

  .employeeName {
    color: #333945;
    font-family: 'Helvetica Neue Bold', sans-serif;
    font-size: 25px;
    //font-weight: 600;
  }

  .date {
    color: rgba(0, 0, 0, 0.34);
    font-family: 'Helvetica Neue Medium', sans-serif;
    font-size: 14px;
    font-weight: 500;
  }

  .stopedBy {
    color: #333945;
    font-size: 13px;
    background: #FFAC00;
    border-radius: 12px;
    height: 40px;
    text-align: center;
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
  }

  .stopedIcon {
    width: 18px;
    height: 18px;
    margin-right: 5px;

    g {
      fill: #fff !important;
      stroke: #FFAC00;
    }

    path {
      fill: #333945 !important;
    }
  }
}

.empty {
  align-self: center;
  font-family: Helvetica Neue, sans-serif;
  font-size: 18px;
  color: #5f6265;
  width: 200px;

  svg {
    width: 174px !important;
    height: 168px !important;
  }

  &__text {
    font-size: 18px;
    line-height: 25px;
    font-family: "Helvetica Neue Medium", sans-serif;
    color: #5f6265;
    margin-top: 18px;
    margin-bottom: 18px;
  }
}

.scrollbarTrackY {
  background-color: transparent !important;
  border-radius: 10px !important;
  width: 7px !important;
  height: calc(100% - 55px) !important;
  top: 45px !important;
  right: -9px !important;
  z-index: 2;

  div {
    background-color: #d5dde1 !important;
    border-radius: 10px !important;
  }
}

.hideOn936 {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 936px) {
    display: none;
  }
}

.hideOn815 {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 815px) {
    display: none;
  }
}

.hideOn750 {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 750px) {
    display: none;
  }
}

.hideOn660 {
  display: flex;
  align-items: center;
  flex-grow: 2;

  @media only screen and (max-width: 660px) {
    display: none;
  }
}

.employeeInfo {
  width: 100%;

  .actionButtons {
    height: 84px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: white;
    border-bottom-right-radius: $sidebar-border-radius;
    border-bottom-left-radius: $sidebar-border-radius;

    button {
      @extend %btn_unset;

      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
    }

    .approve,
    .suspend {
      svg {
        height: 28px;
        width: 28px;
        margin-bottom: 9px;
      }

      text-transform: uppercase;
      font-size: $small-font-size;
    }

    .approve {
      color: $light-green-text;
    }

    .suspend {
      color: $red-text;
    }
  }
}

.multipleEntries {
  width: 100%;
  padding: 0 10px;

  .header {
    align-items: center;
    display: flex;
    color: #333945;
    font-family: 'Helvetica Neue Medium', sans-serif;
    font-size: 16px;
    font-weight: 500;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 25px - #{$actions-min-height});

    > div {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 50%;
    }

    .topBlock {
      justify-content: flex-end;
      padding-bottom: 20px;
    }

    .bottomBlock {
      justify-content: flex-start;
      padding-top: 20px;
    }
  }

  .entryTitle {
    color: #333945;
    font-family: 'Helvetica Neue Bold', sans-serif;
    font-size: 38px;
    //font-weight: 600;
    padding: 10px 0;
  }

  .entryDescription {
    color: #818f94;
    font-family: Helvetica Neue, sans-serif;
    font-size: 20px;
    font-weight: 400;
  }

  .checkboxIcon {
    width: 30px;
    height: 30px;
  }

  .clockIcon {
    width: 34px;
    height: 34px;

    g {
      fill: #0085ff !important;
      stroke-width: 0;
    }

    path {
      fill: #fff !important;
    }
  }

  .actions {
    min-height: $actions-min-height;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    button {
      margin-top: 9px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
