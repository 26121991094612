@import "../variables";

%transition {
  transition: all 0.2s ease-in-out;
}

.button,
%button {
  min-height: 32px;
  box-shadow: 0 1px 2px rgba(9, 16, 43, 0.03);
  border-radius: 6px;
  letter-spacing: -0.17px;
  color: $gray;
  font-family: Helvetica Neue, sans-serif;
  font-size: $small-font-size;
  border: 1px solid #d0dfef;
  background: transparent;
  cursor: pointer;
  outline: transparent;
  white-space: nowrap;
  position: relative;
  margin: 0;
  font-weight: 400;
  display: flex;
  align-items: center;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    border-color: $blue-grownu;
  }
}

.inverse,
%inverse {
  color: #4080fc;
  background: $light-blue2;
  border: 1px solid $light-blue2;

  @extend %transition;
  @extend %button-hover;
}

.white {
  @extend %inverse;

  background: #fff;
  border: 1px solid #0087ff;
}

.cancel {
  color: $gray;
  background: #fff;
  border-color: #fff;
  box-shadow: none;

  &:hover {
    background: $light-blue2;
  }
}

.yellow {
  color: #fff;
  background: $yellow;
  border: 1px solid $yellow;
  font-size: 14px;
  font-family: "Helvetica Neue Medium", sans-serif;
  font-weight: 500;

  @extend %button-hover;
}

.green,
%green {
  color: #fff;
  background: $green;
  border: 1px solid $green;
  font-size: 14px;
  font-family: "Helvetica Neue Medium", sans-serif;
  font-weight: 500;

  @extend %button-hover;

  &.inverse {
    background-color: $light-green;
    color: $light-green-text;
    border: 0;
  }

  &[disabled] {
    color: $gray;
  }
}

.navyBlue {
  @extend %green;

  border: 1px solid $blue-3;
  background: $blue-3;
}

.danger,
%danger {
  color: #fff;
  background: $red;
  border: 1px solid $red;
  font-size: 14px;
  font-family: "Helvetica Neue Medium", sans-serif;
  font-weight: 500;

  @extend %button-hover;
}

.black {
  @extend %danger;

  background: $black;
  border: 1px solid $black;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}

%medium {
  min-height: 42px;
  line-height: 16px;
  border-radius: 5px;
  font-family: "Helvetica Neue Bold", sans-serif;
  //font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.02em;
  padding: 12px 24px;
  min-width: 120px;
}

.medium {
  @extend %medium;
}

.big,
.large {
  @extend %medium;

  height: 42px;
  min-width: 150px;

  &.cancel {
    min-width: auto;
  }
}

.normal {
  line-height: 10px;
  font-family: "Helvetica Neue Medium", sans-serif;
  font-size: 13px;
  font-weight: 500;
  padding: 8px 15px;
}

.small {
  height: 30px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 5px 20px;
}

.fullWidth,
%fullWidth {
  width: 100%;
}

.inline {
  background: transparent;
  border: unset;
  padding: unset;
  display: inline-block;
  box-shadow: unset;
  height: 1em;
  line-height: 1;
  user-select: none;

  &[disabled] {
    color: $gray;
  }
}

.transparent {
  @extend %button;

  background-color: transparent;
  border-color: white;
}

.loading {
  @extend %button;

  &::after {
    content: '';
    position: absolute;
    left: -1px;
    right: -1px;
    top: -1px;
    bottom: -1px;
    background-color: rgba(255, 255, 255, 0.3);
  }
}

.progress {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  max-width: 20px;
  max-height: 20px;

  circle {
    color: $black;
  }
}
