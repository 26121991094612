.rowContent {
  display: flex;
  //border-bottom: 1px solid #d9dfe3;
  height: 41px;
  background: #fff;

  & > * {
    border-left: 1px solid #d9dfe3;
    //border-bottom: 1px solid #d9dfe3;
    border-top: 1px solid #d9dfe3;

    &:first-child {
      border-left-color: transparent;
    }
  }
}
