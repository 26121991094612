.dayTotalModal {
  position: fixed;
  bottom: 75px;
  width: 279px;
  background-color: #fff;
  box-shadow: 0 3px 20px rgba(#647d93, 0.27);
  border-radius: 6px;
  z-index: 999;
  padding: 15px 5px 0 5px;

  &__title {
    font-family: "Helvetica Neue Medium", sans-serif;
    font-size: 14px;
    line-height: 16px;
    height: 16px;
    margin-bottom: 9px;
    margin-left: 10px;
  }

  &__close {
    position: absolute;
    right: 15px;
    top: 13px;
    cursor: pointer;
    width: 20px !important;
    height: 20px !important;
    color: #8a949f;
  }

  .scrollableContent {
    height: 300px !important;
    max-height: 444px !important;
    width: 100% !important;

    .scrollbarTrackY {
      display: flex;
      justify-content: flex-end;
      width: 5px !important;
      height: calc(100% - 10px) !important;
      top: 5px !important;
      bottom: 5px !important;
      right: 0 !important;
      background-color: transparent !important;
      border-radius: 10px !important;
      z-index: 1 !important;
      margin-left: 5px;

      div {
        background-color: #d5dde1 !important;
        border-radius: 10px !important;
        width: 5px !important;
      }
    }
  }
}