@import "../variables";

.commentCard {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 8px;
  border-radius: 13px;
  background-color: #d8ebfb;

  &__header {
    display: flex;
    align-items: center;
    font-family: "Helvetica Neue Bold", sans-serif;
    font-size: 13px;
    line-height: 16px;
    color: #0085ff;
    margin-bottom: 11px;

    svg {
      margin-right: 7px;
    }
  }

  &__image {
    border-radius: 7px;
    width: 100%;
    object-fit: cover;

    & + span {
      margin-top: 30px;
    }
  }

  &__text {
    text-align: left;
    font-family: "Helvetica Neue Medium", sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #333945;
    display: block;
    width: 100%;
  }

  &__image + &__text {
    margin-top: 10px;
  }
}
