
.company {
  margin: 10px 0;
  width: 100%;
  max-width: 430px;

  .companyLogo {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 1px solid #d0dfef;
    overflow: hidden;
    margin-bottom: 30px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

.formControl {
  margin-top: 20px;
  margin-bottom: 20px;
}

.labelBlock {
  display: flex;

  label {
    margin-right: 20px;
  }
}

.formLine {
  width: 100%;
  height: 1px;
  margin-top: 30px;
  background: #edf4fa;
}
