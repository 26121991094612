.buttonsField {
  display: flex;
  align-items: center;
  position: relative;

  &__label {
    font-size: 14px;
    font-family: "Helvetica Neue Medium", sans-serif;
    margin-right: 10px;
    color: #69767a;
  }

  &__button {
    height: 32px;
    min-width: 98px;
    box-sizing: border-box;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 3px;
    background-color: #ebf3fa;
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif;
    margin-left: 2px;

    &_selected {
      background-color: #1685fc;
      color: #fff;
    }
  }
}
