.workTime {
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;

    &__close {
      cursor: pointer;
      position: absolute;
      right: 15px;
      top: 10px;
      color: #d9dfe3;
    }
  
    &__title {
      font-size: 14px;
      font-family: 'Helvetica Neue Medium', sans-serif;
      color: black;
      line-height: 17px;
      text-align: center;

      > svg {
        position: absolute;
        top: 10px;
        width: 20px;
        height: 20px;
        right: 10px;
        color: #808f94;
        cursor: pointer;
      }
    }
  
    &__form {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      margin-bottom: 4px;
    }

    &__button {
      width: auto;
      margin: 0 auto;
    }
}

.workigTime {
  width: 100%;
  max-width: 430px;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;

    label {
      color: #69767a !important;
      font-size: 14px !important;
      text-transform: capitalize;
      display: inline-block;
      min-width: 130px;
      padding-right: 20px;
    }

    label + div {
      margin-top: 0 !important;
    }
  }

  &__to {
    display: inline-block;
    padding-left: 15px;
    padding-right: 10px;
    font-size: 13px;
    color: #aeb2b7;
  }
}
  