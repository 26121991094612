.content {
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: #fff;
  border-radius: 6px;
  padding: 12px 15px;
  box-sizing: border-box;
  z-index: 2008;
  box-shadow: rgba(#647d93, 0.27) 0 3px 20px;

  &_border {
    border: 1px solid #0085ff;
  }

  &__cancel {
    position: absolute;
    background: transparent;
    border: none;
    right: 8px;
    top: 10px;
    cursor: pointer;
  }
}
