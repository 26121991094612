.footer {
  height: 54px;
  display: grid;
  grid-template-columns: minmax(300px, 1fr) repeat(7, minmax(140px, 1fr)) 36px;
  background-color: #feffff;
  box-shadow: 0 3px 16px rgba(#e2e9ed, 0.61);
  border-top: 1px solid #d9dfe3;
  z-index: 5;
  border-radius: 0 0 12px 12px;

  & > *:not(:first-child) {
    border-left: 1px solid #d9dfe3;
  }

  & > *:last-child {
    border-right: 1px solid #d9dfe3;
  }
}
