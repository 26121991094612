@import "../../Core/variables";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 54px;
    height: 54px;
    min-height: 54px;
    border-radius: 50%;
    background-color: #ffeed5;

    svg {
      width: 27px;
      height: 27px;
    }
  }

  &__title {
    margin-top: 19px;
    font-size: 18px;
    line-height: 23px;
    color: #333945;
    font-family: "Helvetica Neue Bold", sans-serif;
  }

  &__subTitle {
    margin-top: 3px;
    font-size: 16px;
    line-height: 19px;
    color: #d4d6d9;
    font-family: "Helvetica Neue Bold", sans-serif;
  }

  &__employee {
    margin-top: 3px;
    font-family: "Helvetica Neue Bold", sans-serif;
    font-size: 18px;
    line-height: 22px;
    color: #fbbc55;
  }

  &__date {
    margin-top: 13px;
    font-size: 13px;
    line-height: 15px;
    color: #9c9da0;
    font-family: Helvetica Neue, sans-serif;
  }

  &__employeeHabbits {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 9px;
    background-color: #f8f8f8;
    border-radius: 13px;
    padding: 11px;
    position: relative;

    &__header {
      display: flex;
      height: 19px;
      font-family: "Helvetica Neue Bold", sans-serif;
      font-size: 13px;
      line-height: 16px;
      color: #8f9091;

      svg {
        margin-right: 7px;
      }
    }

    &__subTitle {
      color: #99a5a9;
      font-size: 11px;
      line-height: 13px;
      margin-left: 31px;
      margin-top: 1px;
      margin-bottom: 9px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      text-align: left;
      font-family: "Helvetica Neue Bold", sans-serif;
      font-size: 13px;
      line-height: 13px;
      color: #abadb2;
      padding-left: 26px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 5px;
        left: 4px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #8f9091;
        z-index: 2;
      }

      &:not(:first-child) {
        margin-top: 12px;
      }

      &__value {
        font-family: "Helvetica Neue Bold", sans-serif;
        font-size: 18px;
        line-height: 18px;
        color: #333945;
      }
    }

    &__line {
      position: absolute;
      top: 71px;
      left: 18px;
      height: 90px;
      width: 1px;
      background-color: #cdced1;
    }

    &__icon {
      margin-right: 7px;
    }
  }
}

.eventInformation {
  display: flex;
  flex-direction: column;
  border-radius: 13px;
  background-color: #fffaf1;
  padding: 12px 11px;
  margin-top: 25px;

  &__progressType {
    display: flex;
    flex-direction: column;

    &__item {
      display: flex;
      flex-direction: column;
      text-align: left;
      font-family: "Helvetica Neue Bold", sans-serif;
      font-size: 13px;
      line-height: 13px;
      color: #abadb2;
      padding-left: 26px;
      position: relative;
      $item: &;

      &::before {
        content: '';
        position: absolute;
        top: 5px;
        left: 4px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #faac2e;
        z-index: 2;
      }

      &:not(:first-child) {
        margin-top: 12px;
      }

      &:last-child::before {
        display: none;
      }

      &:nth-child(3) {
        margin-top: 16px;

        &::after {
          content: '';
          top: -80px;
          left: 7px;
          position: absolute;
          background-color: #faac2e;
          width: 1px;
          height: 40px;
        }
      }

      &__value {
        font-family: "Helvetica Neue Bold", sans-serif;
        font-size: 18px;
        line-height: 18px;
        color: #333945;
      }

      &_range &__value {
        height: 23px;
        display: flex;
        margin-top: 2px;
        font-size: 13px;
        align-items: center;
        justify-content: flex-end;
        color: white;
        border-radius: 3px;
        padding-right: 6px;
      }

      &_range:nth-child(2) &__value {
        background-color: #0087ff;
        width: 90%;
      }

      &_range:nth-child(3) &__value {
        background-color: #faac2e;
      }
    }

    &_overtime &__item {
      padding-left: 0;
      $item: &;

      &:first-child {
        padding-bottom: 8px;
        border-bottom: 1px solid #e9e4db;
      }

      &:not(:first-child) {
        margin-top: 10px;
      }

      &::before,
      &:nth-child(3)::after {
        display: none;
      }
    }
  }

  &__header {
    color: #faac2e;
    display: flex;
    align-items: center;
    font-family: "Helvetica Neue Bold", sans-serif;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 19px;

    svg {
      margin-right: 7px;
    }
  }
}

.cellNameWithAvatar {
  display: flex;

  &__image {
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 4px;
  }
}