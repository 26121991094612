.container {
  background-color: #edf4fa;
  box-sizing: border-box;
  display: flex;
  //justify-content: center;
  height: calc(100vh - 110px);
  min-height: 435px;
  //padding: 0 25px 0 0;
  //width: 100vw;
  //min-width: fit-content;
}

.mainContainer {
  height: calc(100vh - 40px);
  min-height: 400px;
}

.mainContainerWithReports {
  min-height: calc(100% - 85px);
}

.overlay {
  background: #fff;
  border-radius: 12px;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  transition: opacity 0.2s;
  display: none;
  justify-content: center;
  align-items: center;
}

.overlayActive {
  opacity: 0.9;
  transition: opacity 0.2s;
  display: flex;
}

.emptyContainer {
  background-color: #fff;
  box-shadow: 0 3px 16px rgba(226, 233, 237, 0.61);
  display: flex;
  //margin: 2em auto;
  width: 100%;
  border: 1px solid rgba(226, 235, 244, 0.85);
  border-radius: 12px;
  padding-top: 10px;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100%;

  .emptyContent {
    display: flex;
    flex-direction: row;
    text-align: center;
    width: 255px;

    .arrowIcon {
      align-items: center;
      display: flex;
      justify-content: center;
      padding-left: 80px;

      svg {
        width: 160px;
        height: 30px;
      }

      @media only screen and (max-width: 1110px) {
        display: none;
      }
    }

    .reportsIcon {
      width: 74px;
      height: 90px;
      margin-bottom: 15px;
    }

    .title {
      color: #0789ff;
      font-family: 'Helvetica Neue Bold', sans-serif;
      font-size: 15px;
      //font-weight: 600;
      width: 255px;
    }

    .description {
      color: rgba(7, 137, 255, 0.45);
      font-family: 'Helvetica Neue Medium', sans-serif;
      font-size: 15px;
      font-weight: 500;
      width: 255px;
    }

    p {
      margin: 0;
      padding: 6px;
    }
  }
}

.appHeader {
  background-color: #fff;
  border: 1px solid rgba(226, 235, 244, 0.85);
  border-radius: 12px;
  box-shadow: 0 3px 16px rgba(226, 233, 237, 0.61);
  display: flex;
  padding: 13px 20px;
  width: fit-content;
}

.delimiter {
  width: 15px;
  margin-top: 15px;
}

.leftContent {
  padding: 15px 15px 25px 25px;
  width: calc(100% - 287px);
  overflow: hidden;
}

.rightSidebar {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 3px 16px rgba(226, 233, 237, 0.61);
  display: flex;
  justify-content: center;
  padding: 20px 15px;
  position: relative;
  text-align: center;
  min-width: 287px;
  max-width: 287px;
  //min-height: 455px;
  //height: calc(100vh - 30px);
  margin-top: 15px;
  min-height: 400px;
  //height: calc(100vh - 41px);

  .sidebarTitle {
    color: #808f94;
    font-family: 'Helvetica Neue Medium', sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 5px;

    &:first-child {
      padding-top: 0;
    }
  }

  .checkboxGroupWrapper {
    height: 270px;
    margin-top: 10px;
  }

  .multipleEntries {
    width: 100%;

    .header {
      align-items: center;
      display: flex;
      color: #333945;
      font-family: 'Helvetica Neue Medium', sans-serif;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .sidebarContent {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: calc(100% - 41px);
      margin: 15px 0;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-right: 5px;

      &_withButton {
        height: calc(100% - 90px);
      }
    }
  }

  .scrollableContent {
    //overflow: scroll;
    height: fit-content !important;
    width: calc(100vw - 353px) !important;
    //margin-bottom: 5px;
  }
}

.scrollbarTrackX {
  background-color: transparent !important;
  border-radius: 10px !important;
  height: 7px !important;
  bottom: 4px !important;
  left: 10px !important;
  width: calc(100% - 10px) !important;
  z-index: 1 !important;

  div {
    background-color: #d5dde1 !important;
    border-radius: 10px !important;
  }
}

.scrollbarTrackY {
  background-color: transparent !important;
  border-radius: 10px !important;
  width: 7px !important;
  height: calc(100% - 15px) !important;
  top: 5px !important;
  right: 0 !important;
  z-index: 1;

  div {
    background-color: #d5dde1 !important;
    border-radius: 10px !important;
  }
}
