@import "../../Core/variables";

$icon-width: 33px;

@mixin placeholder($color: 'white') {
  &::-webkit-input-placeholder {
    /* Edge */
    color: $color;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color;
  }

  &::placeholder {
    color: $color;
  }
}

.inputWrapper {
  position: relative;
  width: fit-content;
  min-width: 70px;
  max-height: 36px;
}

.input,
%input {
  border: $input-border-style;
  border-radius: 4px;
  box-sizing: border-box;
  color: $input-text-color;
  font-family: Helvetica Neue, sans-serif;
  font-size: 15px;
  padding: 11px;
  outline: none;
  max-height: 36px;
  margin: 0;

  @include placeholder($input-placeholder-color);
}

.inputWrapperFullWidth {
  width: 100%;

  .input {
    width: 100% !important;
    background-color: $input-background-color;
  }

  .light {
    background-color: unset;
  }
}

.iconWrapper {
  display: flex;
  position: absolute;
  justify-content: center;
  width: $icon-width;
  height: 100%;
  top: 0;
  left: 0;

  svg {
    width: 14px;
    height: 14px;
    display: flex;
    align-self: center;
  }
}

.withIcon {
  padding: 11px 11px 11px 30px;
}

.iconLeft {
  .iconWrapper {
    left: -13px - $icon-width;
  }

  .withIcon {
    padding: 11px;
  }
}

@media only screen and (max-width: 768px) {
  .iconLeft {
    .iconWrapper {
      left: -$icon-width;
    }
  }
}

.underlined {
  border: unset;
  border-bottom: 0.5px solid #cbd1d8;
  border-radius: unset;
  background-color: transparent !important;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  @include placeholder();
}

.error {
  border-color: $red;
  color: red;
}
