.popupSave {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1300;
  inset: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);

  &__input{
    border: 1px solid rgba(0, 133, 255, 0.1);
    border-radius: 4px;
    box-sizing: border-box;
    color: #333945;
    font-family: Helvetica Neue, sans-serif;
    font-size: 15px;
    padding: 11px;
    outline: none;
    max-height: 36px;
    margin: 0;
  }

  &__redBorder {
    border: solid 1px #fd0d1b;
  }

  &__content {
    border-radius: 6px;
    border: 1px solid #0085ff;
    background-color: #fff;
    padding: 15px;

    &__title {
      text-align: center;
      font-family: 'Helvetica Neue Medium', sans-serif;
      font-size: 14px;
      color: #000;
      max-width: 249px;
    }

    &__description {
      text-align: center;
      font-family: Helvetica Neue, sans-serif;
      font-size: 12px;
      color: #818f94;
      margin: 8px 0 15px 0;
      width: 163px;
    }

    & > *:not(:first-child) {
      margin-top: 9px;
    }

    button {
      height: 36px;

      & > div {
        margin-top: -5px;
      }
    }
  }
}
