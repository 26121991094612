.filterBlock {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  &__date {
    min-width: 250px;

    input {
      margin-top: -2px;
    }

    & > div {
      width: 100%;
      padding-right: 20px;
    }

    .pickerWrapper {
      width: 100%;
    }
  }
}

.formControl {
  width: auto;
  min-width: 200px;
  margin: 0 10px;

  &:first-child {
    margin-left: 0;
  }

  .selectBlock {
    & select {
      height: 36px;
      box-sizing: border-box;
      padding: 8px 11px;
    }
  }
}

.table {
  margin-top: 20px;
}
