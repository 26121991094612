.autoDeleteBlock {
  width: 100%;
  max-width: 800px;
}

.generalBlock {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  label {
    font-family: "Helvetica Neue Bold", sans-serif;
    padding-bottom: 0;
  }
}

.tooltipBlock {
  margin-left: 20px;
}

.inputDays {
  width: 50px;
}

.formLine {
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #e8eff6;
}