@import "../variables";

.contentBox {
  width: 300px;
  background-color: #fff;
  border: 1px solid $blue-grownu;
  border-radius: 4px;
  padding: 10px;
  position: absolute;
  margin-top: 10px;
  z-index: 3;
}

.fullContentWidth {
  width: auto;
}

.select {
  width: 176px;
}

.inputWrapper {
  cursor: pointer;
  position: relative;
  width: 100%;
  min-width: 60px;
  max-width: 176px;

  &::before {
    content: "";
    position: absolute;
    width: 12.077px;
    height: 7.039px;
    top: 15px;
    right: 10px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.077' height='7.039' viewBox='0 0 12.077 7.039'%3E%3Cpath id='Path_1161' data-name='Path 1161' d='M2360.908,1120.023l4.625,4.624,4.624-4.624' transform='translate(-2359.494 -1118.609)' fill='none' stroke='%230085ff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E%0A") right center no-repeat;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transform: rotate(-90deg);
  }
}

.inputWrapperfullWidth {
  max-width: 100%;
}

.inputWrapperOpened::before {
  transform: rotate(0deg);
}

.customSelect {
  width: 100%;
  height: 36px;
  background-color: #fff;
  border: 1px solid #d0dfef;
  border-radius: 4px;
  outline: none;
  padding: 5px 20px 5px 10px;
  font-family: Helvetica Neue, sans-serif;
  font-size: 15px;
  color: #333945;
  box-sizing: border-box;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.customSelectOpened {
  border: 1px solid $blue-grownu;
}

.scrollableContent {
  //overflow: scroll;
  height: 100vh !important;
  width: 100% !important;
}

.scrollbarTrackX {
  background-color: transparent !important;
  border-radius: 10px !important;
  height: 7px !important;
  bottom: 7px !important;
  left: 15px !important;
  width: calc(100% - 35px) !important;
  z-index: 1 !important;

  div {
    background-color: #d5dde1 !important;
    border-radius: 10px !important;
  }
}

.scrollbarTrackY {
  background-color: transparent !important;
  border-radius: 10px !important;
  width: 7px !important;
  height: calc(100% - 5px) !important;
  top: 5px !important;
  right: 0 !important;
  z-index: 1;

  div {
    background-color: #d5dde1 !important;
    border-radius: 10px !important;
  }
}

:global .styledDropdown {
  .MuiIconButton-root {
    padding: 6px !important;
  }

 .MuiAccordion-root > .MuiButtonBase-root {
    background: #E9F4FF !important;
    border-radius: 4px !important;
    color: #4D7499 !important;
    margin-bottom: 6px;
  }

  .MuiAccordionDetails-root {
    margin-bottom: 6px;
  }

  .MuiAccordion-root .MuiButtonBase-root::after {
    content: none;
  }
}

:global .MuiTypography-body1 {
  line-height: 1.2 !important;
}