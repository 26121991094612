.cellActiveTimerCell {
    
    &__tooltip {
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        background: white;
        border: 1px solid #0085FF;
        border-radius: 4px;
        padding: 5px;
        margin-left: 6px;
        z-index: 10;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        color: #333945;
        font-size: 14px;
        min-width: 150px;
        display: none;
        
      
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            right: 100%;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 8px 8px 0;
            border-color: transparent white transparent transparent;
          }
        
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 100%; /* Aligns with the arrow on the left */
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 9px 9px 9px 0;
            border-color: transparent #0085FF transparent transparent;
            z-index: -1;
        }
    }

    &__button {
        background: #0085FF;
        border-radius: 4px;
        color: white;
        border: none;
        white-space: nowrap;
        width: 100%;
        padding: 3px 10px;
        margin-top: 6px;
        font-size: 14px;
        cursor: pointer;
    }

    &__active {
        position: relative;
        padding: 2px;
        color: #1D88FC;
        background-color: #EDF7FA;
        border-radius: 2px;

        &:hover {

            .cellActiveTimerCell__tooltip {
                display: block;
            }
        }
    }

    &__notactive {
        padding: 2px;
    }

    &__clockin {
        color: #7C7C7C;
        background: #F4F6F9;
        border-radius: 2px;
        padding: 2px;
        display: flex;
        align-items: center;
        gap: 5px;
        white-space: nowrap;

        svg {
            padding-right: 0;
            width: 14px;
            height: 14px;
        }
    }

    &__working {
        padding: 2px;
        display: flex;
        align-items: center;
        gap: 5px;
        white-space: nowrap;

        svg {
            padding-right: 0;
            width: 14px;
            height: 14px;
        }
    }

    &__images {
        background: red !important;
    }
}
  
:global .pswp__zoom-wrap {
    width: 100%;
    height: 100vh;
    transform: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

:global .pswp__img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100vh;
    margin: 0 auto;
    height: 100% !important;
    width: 100% !important;
}