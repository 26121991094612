.timelineWrap {
  width: 100%;
  overflow: hidden;
}

.timelineWrapWithBreaks {
  height: 25px;
}

.timeline {
  background-color: #ff9d00;
  display: flex;
  height: 5px;
  position: relative;
  width: 100%;
}

.timelineWithTimeBreaks {
  margin-bottom: 20px;
}

.timeStart {
  left: -35px;
  display: none;
}

.timeEnd {
  right: -35px;
  display: none;
}

.timespan {
  cursor: pointer;
  display: inline-flex;
  height: 5px;
  position: absolute;
}

.tooltip {
  background-color: gray;
  border-radius: 4px;
  color: #fff;
  display: none;
  font-family: Helvetica Neue, sans-serif;
  font-size: 12px;
  position: absolute;
  top: 20px;
  width: 100px;
  z-index: 1;
}

.worktimes {
  .workTime {
    &:hover,
    &.selected {
      .timeStart,
      .timeEnd {
        display: block;
        background-color: #fff;
        z-index: 1;
      }

      .tooltip {
        display: block;
      }
    }

    &:first-child {
      .timeStart {
        display: block;
        left: 0;
      }

      .timeEnd {
        right: -30px;
      }
    }

    &:last-child {
      .timeStart {
        left: -33px;
      }

      .timeEnd {
        display: block;
        right: 2px;
      }
    }
  }
}

.breaktimes {
  .breakTime {
    &:hover,
    &.selected {
      .timeStart {
        background-color: #fff;
        display: block;
        left: -35px;
        z-index: 1;
      }

      .timeEnd {
        background-color: #fff;
        display: block;
        right: -35px;
        z-index: 1;
      }

      .tooltip {
        display: block;
      }
    }
  }
}

.nighttimes {
  .nightTime {
    &:hover,
    &.selected {
      .timeStart {
        background-color: #fff;
        display: block;
        left: -35px;
        z-index: 1;
      }

      .timeEnd {
        background-color: #fff;
        display: block;
        right: -35px;
        z-index: 1;
      }

      .tooltip {
        display: block;
      }
    }
  }
}

.time {
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
  color: rgba(93, 94, 98, 0.51);
  position: absolute;
  top: 10px;
}

.startTime {
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
  color: rgba(93, 94, 98, 0.51);
  position: absolute;
  left: 0;
  top: 10px;
}

.endTime {
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
  color: rgba(93, 94, 98, 0.51);
  position: absolute;
  right: 0;
  top: 10px;
}
