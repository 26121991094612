@import "../Core/variables";

.overview {
  width: 100%;
  height: auto;
  min-height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 680px;
  }
}

.overviewBlock {
  width: 300px;
  height: 100px;
  border: 1px solid $light-blue2;
  border-radius: 8px;
  margin: 15px;
}

.overviewHead {
  font-size: 20px;
  color: $grey-blue;
  background: $light-blue2;
  text-align: center;
  border-radius: 8px 8px 0 0;
  padding: 3px 5px;
  font-weight: 500;
}

.overviewText {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  margin: 0 auto;
  font-size: 20px;
}
