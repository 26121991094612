@import "../../Core/variables";

$card-border-color: #cbd1d8;
$card-padding: 12px;
$details-padding: 14px 8px 8px;
$edit-border: 1px solid $blue-border;
$arrow-size: 21px;

.roles {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 14px;
  grid-auto-flow: dense;
}

.details {
  position: relative;
  grid-column-start: 1;
  grid-column-end: -1;
  background-color: $beige-color;
  min-height: 440px;
  padding: $details-padding;
  border-radius: $card-padding;
  border: $edit-border;
  display: grid;
  grid-column-gap: $card-padding;
  grid-template-columns: 1.3fr 1fr 1fr;

  &_inner {
    &_title {
      margin-bottom: 7px;

      & > span {
        font-family: "Helvetica Neue Bold", sans-serif;
        color: $gray;
        margin-right: 15px;
        font-size: 14px;
        display: inline-block;
      }

      & > span > span {
        margin-left: 15px;
        display: inline-block;
      }
    }
  }

  .content {
    background-color: white;
    padding: $card-padding;
    border-radius: $card-padding;
    height: calc(100% - 24px);
  }
}

.checklist {
  list-style: none;
  margin: 0;
}

.checkboxGroupWrapper {
  height: 100%;
  margin-top: 10px;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 9;
  border-radius: inherit;
}

.scrollbarTrackY {
  background-color: transparent !important;
  border-radius: 10px !important;
  width: 4px !important;
  height: calc(100% - 21px) !important;
  top: 11px !important;
  right: -9px !important;
  z-index: 2;

  div {
    background-color: #b9b9b9 !important;
    border-radius: 12px !important;
  }
}

.scrollbarTrackX {
  background-color: transparent !important;
  border-radius: 10px !important;
  height: 4px !important;
  bottom: 7px !important;
  left: 15px !important;
  width: calc(100% - 35px) !important;
  z-index: 1 !important;

  div {
    background-color: #b9b9b9 !important;
    border-radius: 10px !important;
  }
}

.reminderTo {
  display: inline-block;
  font-size: 15px;
  color: #333945;

  &__delimiter {
    width: 100%;
    margin: 10px 0;
    height: 1px;
    background-color: #ecf3f9;
  }

  &__select {
    display: inline-block;
    max-width: 71px;
  }
}

.eventRule {
  &__content {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 3px;
    font-size: 15px;
    color: #333945;

    &__input {
      display: inline-block;
      max-width: 71px;
    }

    &__inputBig {
      display: inline-block;
      max-width: 153px;
    }
  }
}

.recipients {
  margin-top: 15px;

  &__title {
    font-size: 14px;
    font-family: "Helvetica Neue Medium", sans-serif;
    color: #808f94;
  }

  &__block {
    border: 1px solid #d0dfef;
    border-radius: 4px;
    padding: 8px;
    margin-top: 6px;

    &__popper {
      display: flex;
      border-radius: 9px !important;
      align-items: center;
      height: 39px;
      box-sizing: border-box;
      padding-left: 12px !important;
      //padding: 0 12px !important;
      font-family: Helvetica Neue, sans-serif !important;
      font-size: 16px !important;
      background-color: #d0dfef !important;
      color: #0085ff !important;
    }

    &__arrow {
      color: #d0dfef !important;
    }

    &__email {
      display: flex;
      align-items: center;
      font-size: 15px;
      line-height: 17px;
      height: 27px;
      box-sizing: border-box;
      background-color: #e8f4ff;
      color: #0085ff;
      border-radius: 100px;
      padding: 4px 8px;

      &:not(:first-child) {
        margin-top: 7px;
      }

      &__delete {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12px;
        height: 12px;
        min-width: 12px;
        padding: 0;
        border-radius: 6px;
        background-color: #0085ff;
        outline: none;
        border: none;
        position: relative;
        margin-right: 9px;
        cursor: pointer;

        svg {
          width: 10px;
          height: 10px;
          fill: white;
          position: absolute;
        }
      }

      &__text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &__input {
      margin-top: 10px;
      font-family: Helvetica Neue, sans-serif;
      color: #0085ff;
      font-size: 15px;
      border: none;
      outline: none;
      border-bottom: 1px solid #d0dfef;
      width: 100%;
    }
  }
}
