@import "../../Core/_variables.scss";

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-family;
}

.card {
  width: 500px;
  min-height: 400px;
  padding: 30px 115px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

p {
  margin: 10px auto;
  font-size: $normal-font-size;
  color: $gray;
}

.title {
  color: $gray;
}

.black {
  color: $black;
  padding-top: 20px;
}

.buttonBlock {
  margin-top: 70px;
  text-align: center;
  border-radius: 10px;
  font-size: $normal-font-size;
  width: 250px;
  height: 100%;
}

.dialogWrapper {
  margin: auto;
  width: 500px;
  font-size: large;
}

.dialogUnderTitle {
  margin-top: 10px;
  font-weight: bolder;
  font-size: medium;
  text-align: center;
  color: gray;
}

.dialogText {
  font-weight: bolder;
  font-size: medium;
  text-align: center;
  color: gray;
  margin-top: 40px;
  margin-bottom: 20px;
}
