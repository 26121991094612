$normal-font-size: 16px;
$small-font-size: 13px;
$background-wrapper-padding: 50px;

$input-border-style: 1px solid rgba(0, 133, 255, 0.1);
$input-background-color: #fcfdfd;
$input-text-color: #333945;
$input-placeholder-color: #aab4bf;

$light-green-row-background: #e6fae3;

$default-text-color: #6c7185;
$link-text-color: #6a42d3;
$light-green: #eaf9ea;
$light-green-text: #57d05a;

$red-text: #f4373e;

$edit-icon-color: #69767a;

$beige-color: #f8fafb;
$blue-border: #e5edf4;

$white: #fff;
$blue: #4080ff;
$blue-2: #4080fc;
$blue-3: #3973be;
$blue-4: #0076ff;
$grey-blue: #4d7499;
$light-blue: #d0dfef82;
$light-blue2: #e5f3ff;
$green: #32c536;
$yellow: #fd9d27;
$red: #fd0d1b;
$gray: #808f94;
$dark-gray: #313946;
$black: #000;
$dashboard-bg: #808f940d;

$main-bg: #edf4fa;

$blue-100: #f6fafde5;
$blue-200: #cce0ff;
$blue-300: #99c2ff;
$blue-400: #66a3ff;
$blue-500: #3385ff;
$blue-600: #3385ff;
$blue-700: #075fe3;
$blue-800: #0e58c6;
$blue-900: #1551aa;

$blue-grownu: #0085ff;
$dark-blue-grownu: #0f75d3;

$green-100: #effbf8;
$green-200: #d7f5ee;
$green-300: #afebdd;
$green-400: #87e2cc;
$green-500: #5ed8bb;
$green-600: #36ceaa;
$green-700: #3cc6a5;
$green-800: #3cb094;
$green-900: #3b9b84;

$gray-100: #f3f7fb;
$gray-200: #dfe6f1;
$gray-300: #bfcada;
$gray-400: #a5b1c4;
$gray-500: #95a8bb;
$gray-600: #6f88a2;
$gray-700: #3d6084;
$gray-800: #224272;
$gray-900: #2d3848;

$orange-100: #fff7ee;
$orange-200: #ffebd5;
$orange-300: #ffd8aa;
$orange-400: #ffc480;
$orange-500: #ffb155;
$orange-600: #ff9d2b;
$orange-700: #df8b2a;
$orange-800: #be7a2a;
$orange-900: #9e6829;

$red-100: #fff1f1;
$red-200: #ffdcdc;
$red-300: #ffb8b8;
$red-400: #ff9595;
$red-500: #ff7171;
$red-600: #ff4e4e;
$red-700: #de4747;
$red-800: #bd4040;
$red-900: #9c3939;

$font-family: helvetica neue, sans-serif;

/* CalendarPicker Variables */
$base-cell-width: 14.2857142857%;
$picker-max-width: 430px;
$expanded-picker-max-width: 570px;

@mixin focusVisible {
  &:focus:not(:focus-visible) {
    outline: none;
  }
}

%btn_unset {
  background-color: unset;
  border: unset;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font-family: $font-family;

  &:focus:not(:focus-visible) {
    outline: none;
  }
}

%button-hover {
  &:hover {
    opacity: 0.8;
  }
}

small.error {
  color: $red-600;
}
