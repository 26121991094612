@import "../../../Core/variables";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__left {
    //margin-right: auto !important;
    &__label {
      font-size: 14px !important;
      color: $gray !important;
      font-family: "Helvetica Neue Bold", sans-serif !important;
    }

    & label + div {
      margin-top: unset;
    }
  }

  button {
    max-width: 257px;
  }
}

.subTitle {
  display: flex;
  margin-top: 20px;
  margin-bottom: 11px;
  font-family: "Helvetica Neue Bold", sans-serif;
  font-size: 14px;
  color: #808f94;

  /* stylelint-disable */
  & > div {
    margin-left: 23px;
  }
}

.viewPassword {
  text-decoration: underline;
  border: 0;
  background: none;
  color: $blue-grownu;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }
}

.footer {
  position: relative;
  margin-left: -9px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;

  > div:last-child {
    border-left: 1px solid #edf4fa;
    margin-left: 20px;
    padding-left: 20px;
  }

  &__loader {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
  }
}
