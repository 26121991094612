.filterBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  &__leftContainer {
    display: flex;
    flex: 1;
    max-width: 221px;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    p {
      font-weight: bold;
    }

    button {
      margin: 5px 0 5px 8px;
      min-width: 100px;

      &:nth-of-type(1) {
        margin-left: 14px;
      }
    }
  }
}

.cellNameWithAvatar {
  display: flex;

  &__image {
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 4px;
  }
}

.margin {
  margin: 0 5px !important;
  width: 189px;

  & > input {
    height: 36,
  }
}
