.section {
  color: #333945;
  background-color: #fff;
  border: 1px solid #d0d6db;
  position: relative;
  height: 32px;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 8px;
  font-family: 'Helvetica Neue Medium', sans-serif;
  font-size: 12px;
  border-radius: 4px;
  $section: &;

  &__outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__skill {
    color: #808F94;
    font-size: 11px;
  }

  &__avatar {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: 1px;
  }

  &__options {
    display: flex;
    align-items: center;
    margin-left: auto;

    &__expander {
      width: 20px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='5' viewBox='0 0 9 5'%3E%3Cpath d='M0,0,4,4,8,0' transform='translate(8.707 4.5) rotate(180)' fill='none' stroke='%238a949f' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      background-color: transparent;
      border: none;
      cursor: pointer;
      transform: rotate(180deg);
      margin-left: 10px;

      &_open {
        transform: unset;
      }
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    height: 100%;

    &__button {
      width: 12px;
      height: 24px;
      min-width: 12px;
      display: flex;
      align-self: center;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      padding: 0;
      border: none;
      cursor: pointer;
      background-color: white;
      position: absolute;
      right: 2px;
      visibility: hidden;

      circle {
        fill: #333945;
      }

      &:hover {
        background-color: #222431;

        circle {
          fill: #fff;
        }
      }
    }

    &__modal {
      position: absolute;
      top: 0;
      right: -204px;
      background-color: #fff;
      border: 1px solid #0085ff;
      border-radius: 6px;
      padding: 12px 15px 6px 15px;
      width: 200px;
      box-sizing: border-box;
      z-index: 5;

      &__title {
        color: #000;
        font-size: 14px;
        line-height: 17px;
        font-family: 'Helvetica Neue Medium', sans-serif;
        margin-bottom: 12px;
      }

      &__item {
        cursor: pointer;
        width: 100%;
        padding: 12px 0 9px 0;
        font-family: Helvetica Neue, sans-serif;
        font-size: 12px;
        line-height: 14px;
        color: #818f94;
        border: none;
        border-top: 1px solid rgba(#707070, 0.24);
        background-color: transparent;
        text-align: left;
      }

      &_top {
        top: unset;
        bottom: 0;
      }

      &_left {
        left: unset;
        right: 0;
      }
    }
  }

  &:hover #{$section}__menu__button {
    visibility: visible;
  }

  &_openMenu #{$section}__menu__button {
    visibility: visible;
    background-color: #222431;

    circle {
      fill: #fff;
    }
  }

  &__demand {
    position: static !important;
    width: auto !important;
    height: auto !important;
    border: 1px solid #d0d6db;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 3px;
    margin-left: auto;
    margin-right: 10px;

    &_red {
      color: #AC6363;
    }

    &_gray {
      color: #7F7F7F;
    }

    &_green {
      color: #05985F;
    }

    &_orange {
      color: #FD9D27;
    }

    + img {
      margin-left: 0;
    }
  }

  &__buttonDots {
    position: absolute;
    right: 2px;
  }
  
  &__title {
    font-family: "Helvetica Neue Medium", sans-serif;
    font-size: 14px;
    color: black;
    padding-bottom: 12px;
    width: 168px;
  }

  &__space {
    margin-top: -7px;
  }

  &_addEmployees {
    border: 0.5px dashed #C4CAD0;
    color: #1685FC;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    $section: &;

    &:hover {
      border: 0.5px solid #1685FC;

      #{$section}_circle {
        background-color: #1685FC;
        color: white;
        border: 0.5px solid #1685FC;
      }
    }

    &_circle {
      border: 0.5px dashed #1685FC;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 2px;
    }
  }
}
