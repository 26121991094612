@import "../../Core/variables";

$icon-width: 33px;

@mixin placeholder($color: 'white') {
  &::-webkit-input-placeholder {
    /* Edge */
    color: $color;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color;
  }

  &::placeholder {
    color: $color;
  }
}

.inputWrapper {
  position: relative;
  width: fit-content;
  min-width: 70px;
  max-height: 36px;
}

.input,
%input {
  border: $input-border-style;
  border-radius: 4px;
  box-sizing: border-box;
  color: $input-text-color;
  font-family: Helvetica Neue, sans-serif;
  font-size: 15px;
  padding: 11px;
  outline: none;
  max-height: 36px;
  margin: 0;
  padding-right: 60px;

  &:focus + div.iconClearWrapper::after {
    content: '';
    position: absolute;
    right: 0;
    top: 8px;
    bottom: 8px;
    border-right: 1px solid #D0DFEF;
  }

  @include placeholder($input-placeholder-color);
}

.inputWrapperFullWidth {
  width: 100%;

  .input {
    width: 100% !important;
    background-color: $input-background-color;
  }

  .light {
    background-color: unset;
  }
}

.iconWrapper {
  display: flex;
  position: absolute;
  justify-content: center;
  width: $icon-width;
  height: 100%;
  top: 0;
  right: 2px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    display: flex;
    align-self: center;
  }
}

.iconClearWrapper {
  display: flex;
  position: absolute;
  justify-content: center;
  width: $icon-width;
  height: 100%;
  top: 0;
  right: 35px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    display: flex;
    align-self: center;
  }
}

.underlined {
  border: unset;
  border-bottom: 0.5px solid #cbd1d8;
  border-radius: unset;
  background-color: transparent !important;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  @include placeholder();
}

.error {
  border-color: $red;
  color: red;
}

.predictions {
  position: absolute;
  top: 38px;
  right: 0;
  left: 0;
  z-index: 10;
  border: 1px solid #D9DCE2;
  border-radius: 4px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  background-color: #fff;

  &__item {
    padding: 8px 16px;

    &:hover {
      background-color: #F7F8FA;
    }

    &__selected,
    &__selected:hover {
      background-color: #E3E5EA;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    background-color: #F7F8FA;

    > div {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  &__fit,
  &__point,
  &__cancel,
  &__select {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    font-size: 12px;
    gap: 2px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 4px;

    svg {
      width: 16px;
      height: 16px;
    }

    &:hover {
      background-color: #E3E5EA;
    }

    &:active,
    &:hover:active {
      background-color: #0770E3;
    }
  }

  &__cancel,
  &__select {
    min-width: 75px;
  }
}