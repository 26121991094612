.sectionEmpty {
  justify-content: space-between;
  border: 1px dashed #c4cad0;
  background-color: white;
  cursor: pointer;
  color: #818f94;
  height: 32px;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-family: 'Helvetica Neue Medium', sans-serif;
  font-size: 12px;
  border-radius: 4px;

  &__iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    right: 15px;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: 1px dashed #1685fc;
  }
}
