@import "../variables";

.pickerWrapper {
  //position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: 36%;
  min-width: 185px;
  max-width: 318px;
}

.pickerWrapperRight {
  position: static;
}

.pickerDefaultWrapper {
  position: absolute;
  top: 90px;
  left: 90px;
  z-index: 10;
  border-radius: 6px;
}

.dateInput {
  padding: 8px 12px;
  border: 1px solid #d0dfef;
  border-radius: 4px;
  color: #333945;
  font-family: Helvetica Neue, sans-serif;
  font-size: 16px;
  width: calc((100% - 36px - 26px) / 2);
  max-width: 126px;

  &:focus {
    outline: none;
  }

  &.active {
    color: $blue-grownu;
    border: 1px solid $blue-grownu;
    outline: none;
  }
}

.dateInputSmall {
  width: 80px;
  padding: 6px;
}

.to {
  color: #cbd1d8;
  font-family: Helvetica Neue, sans-serif;
  font-size: 15px;
  padding: 0 5px;
}

.calendarIcon {
  color: #808f94;
  padding: 5px;
}

.calendarIconSmall {
  padding: 3px;
}

.pickerRightWrapper {
  top: 55px;
  right: 15px;
  position: absolute;
  width: 583px;
  z-index: 2;
}

.calendarIconWrapper {
  border: 1px solid #d0dfef;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
  outline: none;

  &:hover {
    border-color: $blue-grownu;

    .calendarIcon {
      color: $blue-grownu;
    }
  }

  &.active {
    border-color: $blue-grownu;

    .calendarIcon {
      color: $blue-grownu;
    }
  }
}

.definedRanges {
  background-color: #fff;
  border: 1px solid $blue-grownu;
  border-radius: 6px;
  position: absolute;
  top: 95px;
  width: 275px;
  padding: 5px 10px;
  z-index: 3;

  button {
    margin: 5px 0;
  }
}

.definedRangesRight {
  top: 125px;
  right: 15px;
}

.pickerRightWrapper {
  top: 55px;
  right: 35px;
  position: absolute;
  width: 580px;
  z-index: 1000;
}