.timeRangeColor {
  display: flex;
  position: relative;
  flex: 1;
  height: 32px;
  $timeRangeColor: &;
  $widthMenuModal: 200;
  $widthMenuModal: 200;

  &__button {
    height: 32px;
    padding: 0 7px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: Helvetica Neue, sans-serif;
    border: 1px solid #d9dfe3;
    border-radius: 4px;
    color: #333945;
    background-color: #fff;
    font-size: 14px;
    cursor: pointer;

    .timeRangeColor_copyTool & {
      background-color: #14121F;
      border-color: #14121F;
      color: #ACABB4;
      width: 110px;
    }

    &__nightTime {
      position: absolute;
      top: 3px;
      right: 3px;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: #fd9d27;

      &:hover {
        &::after {
          content: attr(data-title);
          background-color: #fff;
          border-radius: 6px;
          position: absolute;
          bottom: 9px;
          left: -41px;
          right: -41px;
          padding: 7px 10px;
          box-shadow: 0 2px 8px rgba(#647d93, 0.2);
        }
      }
    }

    &:disabled {
      position: relative;
      cursor: not-allowed;
      background-color: #f5f7f8;
      color: #818f94;
      border-color: #d0d6db;

      &:hover {
        &::after {
          content: attr(disabled-text);
          background-color: #fff;
          border-radius: 6px;
          position: absolute;
          bottom: 34px;
          left: -50px;
          right: -50px;
          padding: 5px 12px;
          box-shadow: 0 2px 8px rgba(#647d93, 0.2);
        }

        //&::before {
        //  content: '';
        //  position: absolute;
        //  bottom: 32px;
        //  width: 0;
        //  height: 0;
        //  border-left: 12px solid transparent;
        //  border-right: 12px solid transparent;
        //  border-top: 9px solid #fff;
        //}
      }
    }
  }

  &__dots {
    width: 12px;
    height: 24px;
    min-width: 12px;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    padding: 0;
    border: none;
    cursor: pointer;
    background-color: white;
    position: absolute;
    right: -13px;

    circle {
      fill: #333945;
    }

    &:hover {
      background-color: #222431;

      circle {
        fill: #fff;
      }
    }
  }

  &__modal {
    position: absolute;
    top: 35px;
    background-color: #fff;
    border: 1px solid #0085ff;
    border-radius: 6px;
    padding: 9px 10px 9px 10px;
    width: 165px;
    box-sizing: border-box;
    z-index: 5;

    .timeRangeColor_copyTool & {
      background-color: #312B4C;
      border-color: #312B4C;
    }

    &__title {
      font-size: 14px;
      font-family: "Helvetica Neue Medium", sans-serif;
      color: #333945;

      .timeRangeColor_copyTool & {
        color: #fff;
      }
    }

    &__labels {
      display: flex;
      margin-top: 9px;
      margin-bottom: 7px;
      font-family: Helvetica Neue, sans-serif;
      color: rgba(#333945, 0.5);
      font-size: 12px;

      span {
        width: 77px;
      }

      .timeRangeColor_copyTool & {
        color: #9995a5;
      }
    }

    &__content {
      display: flex;
      height: 169px;
      border-radius: 4px;
      border: 1px solid #d0dfef;
      overflow: hidden;

      .timeRangeColor_copyTool & {
        border-color: #474260;
      }

      :global(.ScrollbarsCustom-Content) {
        padding: 6px 3px !important;
      }

      & > div:first-child {
        border-right: 1px solid #d0dfef;

        .timeRangeColor_copyTool & {
          border-color: #474260;
        }
      }
    }

    &__buttons {
      display: flex;
      height: 36px;
      margin-top: 9px;

      button {
        width: 68px;

        .timeRangeColor_copyTool & {
          background-color: #E5E9ED;
          color: #333945;
        }

        &:first-child {
          background-color: #e5e9ed;
          color: #333945;
          margin-right: 6px;
          padding: 0;
          text-align: center;

          .timeRangeColor_copyTool & {
            background-color: #474260;
            color: #fff;
          }
        }
      }
    }

    &_top {
      top: unset;
      bottom: 35px;
    }

    &_left {
      left: unset;
      right: 0;
    }
  }

  &_openMenu #{$timeRangeColor} {
    &__button {
      border-color: #222431;
    }

    &__dots {
      background-color: #222431;

      circle {
        fill: #fff;
      }
    }

    &__modal {
      top: 0;
      right: -#{$widthMenuModal + 14}px;
      width: #{$widthMenuModal}px;

      &__user {
        display: flex;
        margin-bottom: 12px;

        &__avatar {
          width: 30px;
          height: 30px;
          border-radius: 15px;
        }

        &__info {
          display: flex;
          flex-direction: column;
          margin-left: 10px;

          &__fullName {
            font-family: 'Helvetica Neue Medium', sans-serif;
            font-size: 14px;
            line-height: 17px;
            color: #000;
          }

          &__jobType {
            font-family: Helvetica Neue, sans-serif;
            font-size: 11px;
            line-height: 16px;
            margin-top: 2px;
            color: #808f94;
          }
        }
      }

      &__item {
        cursor: pointer;
        width: 100%;
        padding: 9px 0;
        font-family: Helvetica Neue, sans-serif;
        font-size: 12px;
        line-height: 14px;
        color: #333945;
        border: none;
        border-top: 1px solid rgba(#707070, 0.24);
        background-color: transparent;
        text-align: left;
      }

      &_left {
        left: -#{$widthMenuModal + 1}px;
      }

      &_top {
        bottom: 0;
        top: auto;
      }
    }
  }
}

.buttonTime {
  width: 55px;
  height: 36px;
  padding: 0 6px;
  border: none;
  background-color: #fff;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  box-sizing: border-box;

  .timeRangeColor_copyTool & {
    background-color: #312B4C;
    color: #fff;
  }

  &_selected {
    background-color: #d9f4f9;

    .timeRangeColor_copyTool & {
      background-color: #474260;
    }
  }
}

.scrollbarTrackY {
  background-color: transparent !important;
  border-radius: 10px !important;
  width: 3px !important;
  z-index: 2;
  right: 4px !important;

  div {
    background-color: #d5dde1 !important;
    border-radius: 10px !important;
    //height: 47px !important;

    .timeRangeColor_copyTool & {
      background-color: #474260;
    }
  }
}
