@import './../../../Core/variables';

.description {
  //white-space: pre-wrap;
  text-align: center;
  line-height: 1.5;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
