@import "./../Core/variables";

.container {
  background-color: #edf4fa;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  width: 100vw;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 600px;
  padding-top: 10px;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.content {
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 300px;
}

.title {
  color: #333945;
  font-family: "Helvetica Neue Bold", sans-serif;
  font-size: 25px;
  margin-top: 0;
}

.delimiter {
  margin-left: 15px;
  height: 15px;
}

.errorBlock {
  height: 20px;
  color: $red;
  text-align: right;

  p {
    margin: 0;
    font-size: 12px;
  }
}

.porgressBlock {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -3px;
}

footer {
  position: fixed;
  bottom: $background-wrapper-padding;

  .forgotLink {
    color: white;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    background-color: red;
  }

  .wrapper {
    padding: 10px 20px !important;
  }

  .content {
    min-width: 250px;
  }

  .buttons {
    display: flex;
    //flex-direction: column;
    //justify-content: space-between;
    //align-items: unset;
    button {
      min-width: 100px;
    }

    & > label {
      margin-bottom: 5px;
    }
  }
}
