.datePicker {
  display: flex;
  align-items: center;
  position: relative;

  &__label {
    font-size: 15px;
    font-family: Helvetica Neue, sans-serif;
    margin-right: 10px;
    color: #818f94;
  }

  &__input {
    border: 1px solid #d0dfef !important;
    border-radius: 4px;
    width: 126px;

    :global(.MuiInputBase-root) {
      padding: 0 13px !important;

      &::before,
      &::after {
        display: none;
      }
    }
  }
}

:global(.MuiPickersToolbar-toolbar) {
  background-color: #0085ff !important;
}

:global(.MuiPickersDay-daySelected) {
  background-color: #0085ff !important;
}

:global(.MuiButton-textPrimary) {
  color: #0085ff !important;
}