@import "../variables";

.titleBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 31px 20px 20px 30px;

  .leftBlock {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-size: 24px;
    color: $blue-2;
    margin: 0 10px 0 15px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 90px;
    top: auto;
    bottom: 0;
    right: 0;
    width: auto;
    height: 1px;
    background: $main-bg;
  }

  .infoBlock {
    display: inline-block;
    background-color: $light-blue;
    padding: 3px 7px;
    color: $grey-blue;
    border-radius: 5px;
    font-size: 13px;
    margin-left: 15px;
    margin-right: 15px;

    &__inner {
      display: inline-block;
      position: relative;
      padding-left: 7px;
      padding-right: 7px;

      &::after {
        content: ".";
        position: absolute;
        left: -5px;
        right: auto;
        top: -17px;
        font-size: 28px;
      }

      &:first-child {
        &::after {
          display: none;
        }
      }
    }
  }
}

.buttonBlock {
  & button {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}
