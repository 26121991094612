@import "../../variables";
@import "../../Button/Button.module";
@import "../../Input/Input.module";

.inner {
  max-width: 1300px;
  min-width: 900px;
  width: 100%;
  padding: 10px 15px;
  position: relative;
}

input[type='file'] {
  display: none;
}

.upload {
  @extend %button;
  @extend %inverse;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textarea {
  @extend %input !optional;

  width: 100%;
  max-height: none;

  p {
    margin: 0;
  }

  span {
    font-weight: bold;
  }
}

.fakeInput {
  @extend %input !optional;

  min-height: 40px;
}

.import {
  display: grid;
  align-items: center;
  grid-template-columns: 430px repeat(2, 150px);
  grid-column-gap: 15px;

  &Table {
    position: relative;
  }

  &IconWrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;

    .icon {
      height: 168px;
      width: 168px;
    }

    p {
      font-size: 18px;
      max-width: 235px;
      text-align: center;
    }
  }

  &Footer {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  &Stats {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 10px;
    align-items: end;
  }
}

.loader {
  position: absolute;
  display: flex;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  z-index: 9;
  justify-content: center;
  align-items: center;
}
