.timeSheet {
    display: flex;
    flex-direction: column;
    width: 210px;
    margin-top: 5px;
  
    &__label {
      display: flex;
  
      label {
        margin-right: 23px;
      }
    }
  }

.generalBlock {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 15px;
  margin-left: -9px;
}

.hr {
  height: 1px;
  background: #e8eff6;
  margin: 20px 0 23px 0;
}

.generalBlock2 {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;

  label {
    font-family: "Helvetica Neue Bold", sans-serif;
  }
}