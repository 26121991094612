@import "../../Core/variables";

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-family;
}

.card {
  max-width: 536px;
  width: 100%;
  min-height: 400px;
  padding: 50px 115px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.description {
  text-align: center;
  margin-top: -14px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 18px;
  color: $gray;

  &__email {
    font-size: 13px;
  }
}

.buttonBlock {
  margin-top: 50px;
  text-align: center;
  max-width: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.errorBlock {
  height: 20px;
  color: $red;
  text-align: right;

  p {
    margin: 0;
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .card {
    padding: 30px 50px;
  }

  .buttonBlock {
    margin-top: 25px;
  }
}
