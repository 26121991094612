@import "../../../Core/variables";

.changeLog {
  display: flex;
  flex-direction: column;
  width: 900px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 4px;

 
}
