.holiday_icon {
  cursor: pointer;

  &.month {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -8px;
    z-index: 3;
  }

  &.inline {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
  }
}