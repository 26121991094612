.root {
  box-shadow: none;
  border-bottom: 1px solid rgba(112, 112, 112, 0.24);
}

.arrowIcon {
  fill: none;
  stroke: #4D7499;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.expandIcon {
  color: #4D7499;
  fill: #4D7499;
  transform: rotate(-90deg);
  padding: 0 !important;
  margin: 0 !important;
  width: 8.315px !important;
  height: 100% !important;
}

.checkboxLabel {
  margin: 0 !important;
}

.expandIconExpanded {
  transform: rotate(180deg) !important;
  padding: 0 !important;
  margin: 0 !important;
}

.checkboxGroupItem {
  margin-left: 1px !important;

  &:first-child {
    position: relative;

    &::after {
      
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: rgba(112, 112, 112, 0.24);
      display: block;
      bottom: 0;
    }
  }

  & > div {
    padding-top: 0;
    padding-bottom: 0;
  }
}